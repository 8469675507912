export const columns = [
	{
		prop: 'modelName',
		label: '车型名称',
		width: '280px',
	},
	{
		prop: 'carNo',
		label: '自编号',
		width: '120px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '140px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '140px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '140px',
	},
	{
		prop: 'endDate',
		label: '年检结束日期',
		width: '140px',
	},
	{
		prop: 'createTime',
		label: '年检操作时间',
		width: '180px',
	},
	{
		prop: 'createUserName',
		label: '年检操作人',
		width: '140px',
	},

	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择年检结束日期',
			label: '年检结束日期',
			attr: 'endDate',
			type: 'date',
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'multi-select',
			width: 320,
			options: [],
		},
		{
			placeholder: '请选择司服伙伴',
			label: '司服伙伴',
			attr: 'driverSupportId',
			type: 'select',
			options: [],
		},
	],
}
