<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">年检记录</div>
				<div class="header-button">
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/car-center/annual-audit/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					showSelection
					autoWidth
					@selection-change="onSelectionChange"
				>
					<template #tradeBillType="{ value }">
						<span>{{ mapDict('trade_bill_type', value) }}</span>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('trade_bill_status', value) }}</span>
					</template>
					<template #month="{ row }">
						<span>{{ convertDate(row, 'month') }}</span>
					</template>
					<template #statementDate="{ row }">
						<span>{{ convertDate(row) }}</span>
					</template>
					<template #operation="{ row }">
						<span
							class="click"
							@click="onDelete(row)"
							v-permission="'pc/car-center/annual-audit/delete'"
						>
							删除
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'AnnualInspection',
})
</script>

<script setup name="AnnualInspection">
import dayjs from 'dayjs'
import { inject, onActivated, ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { getStore } from '@/utils/store'
import { ElMessageBox } from 'element-plus'
import { setting, columns } from './config'
import { useState, useFetch, useDict, useFilter } from '@/utils/hooks.js'
import {
	companySelect,
	maintenanceSelect,
	annualInspectionList,
	deleteAnnualInspection,
	exportAnnualInspection,
} from '@/api/vehicle.js'
import { handleExport } from '@/utils/util'

const $message = inject('$message')
const userInfo = getStore({ name: 'userInfo' })

const settingRef = ref(setting)

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])

const [loading, setLoading] = useState(false)
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = annualInspectionList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(
			data.records.map(item => ({
				...item,
				endDate: dayjs(item.endDate).format('YYYY-MM-DD'),
			})),
		)
		setTotal(data.total)
	}
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
function convertDate(val, type) {
	const { statementDate } = val
	if (type === 'month') return dayjs(statementDate).format('YYYY-MM')
	return dayjs(statementDate).format('YYYY-MM-DD')
}

async function onExport() {
	setLoading(true)
	try {
		const res = await exportAnnualInspection(params.value)
		if (res) handleExport(res, '年检记录')
	} catch (err) {
		$message.error(err.msg || err.message)
	} finally {
		setLoading(false)
	}
}
function onDelete(row) {
	const { id } = row
	ElMessageBox.confirm('是否确认删除当前年检记录', '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	})
		.then(async () => {
			const { err } = await fetchData(deleteAnnualInspection, id)
			if (err) return $message.error(err.message)
			$message.success('操作成功')
			fetchList()
		})
		.catch(() => {})
}
async function fetchSelect() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [driverSupportRes, compRes] = await Promise.all([
		useFilter(() => maintenanceSelect('DriverService', arr, { isAll: true }), {
			name: 'realName',
			code: 'id',
		}),
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
	])
	if (!driverSupportRes.err) {
		const driverSupport = findItem('driverSupportId')
		driverSupport.options = driverSupportRes.data
	}
	if (!compRes.err) {
		const company = findItem('companyId')
		company.options = compRes.data
	}
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}
function onSelectionChange(val) {}
function onChangeFilter(event) {
	const params = { ...event }
	if (Array.isArray(params.companyId) && !params.companyId.length) {
		delete params.companyId
	}
	if (Array.isArray(params.endDate) && params.endDate.length) {
		const [start, end] = params.endDate
		params.endDateBegin = start
		params.endDateEnd = end
	} else {
		delete params.endDateBegin
		delete params.endDateEnd
	}
	setParams(params)
	fetchList()
}

async function fetchDict() {
	const dict = await useDict(['trade_bill_status', 'sex', 'trade_bill_type'])
	if (dict) setDict(dict)
}
function init() {
	fetchDict()
	fetchList()
	fetchSelect()
	const route = useRoute()
	const query = route.query
	if (Object.keys(query).length > 0) {
		params.value.keyword = query.keyword || ''
		params.value.status = query.status || null
		params.value.companyId = query?.companyIds?.split(',') || []
		const [start, end] = query?.statementDate?.split(',') || []
		params.value.statementDate =
			(query?.statementDate && [`${start} 00:00:00`, `${end ? end : start} 23:59:59`]) || null
		const ins = getCurrentInstance()
		ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
	}
}
onActivated(() => {
	init()
})
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
